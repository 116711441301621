import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, JsonRpcProvider  } from 'ethers';
import contractABI from './Namomigos.json';
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  ConnectButton
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { polygon } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Text,
  VStack,
  ChakraProvider,
  Avatar,
  extendTheme,
  Link,
  Spacer,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogFooter,
  useBreakpointValue,
} from '@chakra-ui/react';

// 配置链和提供者
const { chains, publicClient } = configureChains(
  [polygon], // 或你要使用的链
  [
    alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
    publicProvider()
  ]
);

// 获取默认钱包
const { connectors } = getDefaultWallets({
  appName: 'Namomigos',
  projectId: '2a5cad646e89e1acc7711a28eebc6d7a',
  chains
});

// 创建 Wagmi 客户端
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})


function App() {
  const [mintAmount, setMintAmount] = useState(1);
  const contractAddress = "0x256Fa702a12A0A77750058b528C94819588fB3A5";
  const abi = contractABI.abi;
  const [nftImages, setNftImages] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [remainingNFTs, setRemainingNFTs] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isMintSuccessOpen, setIsMintSuccessOpen] = useState(false);

  useEffect(() => {
    const gifUrl = "assets/features/web_gif.gif"; // 替换为您的GIF URL
    setNftImages([gifUrl]); // 将gif动画作为唯一的元素放入数组
    setSelectedNFT(gifUrl); // 设置选中的NFT为您的gif动画

    const fetchNFTData = async () => {
      const provider = new JsonRpcProvider('https://polygon-mainnet.infura.io/v3/d07ebfbf59c2477d96a356cc46045b60');
      const contract = new Contract(contractAddress, abi, provider);       
      const totalSupply = await contract.totalSupply();
      const totalSupplyNumber = Number(totalSupply);   
      const displayCount = Math.min(246, totalSupplyNumber);   
      const randomIndexes = new Set();
      while (randomIndexes.size < displayCount) {
        const randomIndex = Math.floor(Math.random() * totalSupplyNumber) + 1; // 索引从1开始
        randomIndexes.add(randomIndex);
      }
    
      const tokenURIs = await Promise.all(
        Array.from(randomIndexes).map(i => contract.tokenURI(i))
      );     
      // 获取NFT的详细信息
      const nftDataPromises = tokenURIs.map(uri => fetch(uri).then(res => res.json()));
      const nftData = await Promise.all(nftDataPromises);
      // 提取图片URL
      const nftImages = nftData.map(data => data.image);
      setNftImages(nftImages);
    };

    // 异步函数获取剩余NFT数量
    const getRemainingNFTs = async () => {
      const provider = new JsonRpcProvider('https://polygon-mainnet.infura.io/v3/d07ebfbf59c2477d96a356cc46045b60');
      const contract = new Contract(contractAddress, abi, provider);    
      try {
        const totalSupply = await contract.totalSupply();
        const totalSupplyNumber = Number(totalSupply);
        const maxSupply = 9981; // 假设的总供应量
        setRemainingNFTs(maxSupply - totalSupplyNumber); // 更新剩余NFT数量
      } catch (error) {
        console.error('Error fetching total supply:', error);
      }
    };
    fetchNFTData();
    getRemainingNFTs();
  }, [abi, contractAddress]);

  const theme = extendTheme({
    fonts: {
      heading: "'Pixelify Sans', sans-serif",
      body: "'Pixelify Sans', sans-serif",
    },
    styles: {
      global: {
        'html, body': {
          color: 'black', // 设置全局字体颜色为黑色
          textShadow: `
            -1px -1px 0 #fff,  
            1px -1px 0 #fff,
            -1px 1px 0 #fff,
            1px 1px 0 #fff
          `, // 为所有文本添加白色“边框”
          fontWeight: 'bold', // 加粗文本
          fontSize: '20px', // 这里设置全局字体大小
        },
      },
    },
  });
  
  const mintNFT = async () => {
    try {
      console.log("Connecting to Ethereum provider...");
      const provider = new BrowserProvider(window.ethereum);
      console.log("Provider:", provider);
  
      console.log("Getting signer...");
      const signer = await provider.getSigner();
      console.log("Signer:", signer);
  
      console.log("Creating contract instance...");
      const contract = new Contract(contractAddress, abi, signer);
      console.log("Contract:", contract);
  
      console.log("Fetching current price...");
      const currentPriceWei = await contract.plPrice(); 
      console.log("Current Price in Wei:", currentPriceWei.toString());
  
      // 将价格转换为BigInt
      const currentPrice = window.BigInt(currentPriceWei);
  
      // 计算总价
      const totalPrice = currentPrice * window.BigInt(mintAmount);
      console.log(`Total price for minting (in Wei): ${totalPrice}`);
  
      console.log(`Preparing to mint ${mintAmount} NFT(s)...`);
      const txResponse = await contract.publicMint(mintAmount, {
        value: totalPrice.toString()
      });
      console.log("Waiting for transaction to be mined...");
      await txResponse.wait();
      setIsMintSuccessOpen(true);
      console.log(`${mintAmount} NFT(s) minted successfully.`);
    
    } catch (error) {
      console.error('Error minting NFT:', error);
      if (error.message.includes("Contract is paused")) {
        setAlertMessage("Contract is paused");
        setIsAlertOpen(true);
      }
    }
  };  

  const RoadmapItem = ({ items }) => {
    return (
      <Flex direction={{ base: "column", md: "row" }} justify="center" align="center" p={4}>
        {items.map((item, index) => (
          <Box key={index} mx={{ base: 2, md: 4 }} mb={{ base: 4, md: 0 }} position="relative">
            <Image src={item.img} boxSize={{ base: "150px", md: "200px" }} />
            <Text
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              bg="rgba(0, 0, 0, 0.7)" // 半透明背景
              color="white"
              textAlign="center"
              opacity="0" // 默认不显示文本
              _hover={{ opacity: "1" }} // 鼠标悬浮时显示文本
              transition="opacity 0.5s" // 平滑过渡效果
            >
              {item.text}
            </Text>
          </Box>
        ))}
      </Flex>
    );
  };   
    
  const TeamMember = ({ name, bio, avatar }) => {
    const [showBio, setShowBio] = useState(false);
  
    return (
      <VStack m={6} alignItems="center" w="120px" /* 设置一个固定宽度 */>
        <Avatar 
          src={avatar ? avatar : '默认头像的路径'} 
          cursor="pointer" 
          onClick={() => setShowBio(!showBio)} 
        />
        {/* 设置文本溢出处理以保持名称在一行 */}
        <Text isTruncated fontSize="sm" fontWeight="bold">{name}</Text>
        {showBio && <Text fontSize="xs">{bio}</Text>}
      </VStack>
    );
  };   
      
  const FAQItem = ({ question, answers }) => {
    const [showAnswer, setShowAnswer] = useState(false);
    const maxWidth = useBreakpointValue({ base: '100%', md: '650px' }); // 在这里设置响应式宽度
  
    return (
      <Box textAlign="center">
        <Text mb={2} fontWeight="bold" cursor="pointer" onClick={() => setShowAnswer(!showAnswer)}>
          {question}
        </Text>
        {showAnswer && (
          <VStack>
            {answers.map((answer, index) => (
              <Flex key={index} direction={{ base: "column", md: "row" }} align="center">
                <Avatar src={answer.avatar} size="sm" mr={2} />
                <Text fontSize="sm" style={{ wordWrap: 'break-word', maxWidth: maxWidth }}> {/* 应用 maxWidth */}
                  {answer.text}
                </Text>
              </Flex>
            ))}
          </VStack>
        )}
      </Box>
    );
  };  

  return (
    <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider chains={chains}>
    <ChakraProvider theme={theme}>
      {/* 背景图片设置 */}
      <Box position="fixed" top="0" right="0" bottom="0" left="0" bgImage="url('/assets/background/bg.png')" bgSize={{ base: "auto 100%", md: "cover" }} bgPos={{ base: "center", md: "top" }} zIndex="-1" />
      
      {/* 页头部分 */}
      <Flex justify="space-between" align="center" p={4}>
        {/* 左边的 logo */}
        <Flex align="center">
          <Link href="https://twitter.com/Namomigos" isExternal mx={2}>
            <Image src="/assets/logos/twitter_X.png" alt="Twitter" boxSize="2em" />
          </Link>
          <Image src="/assets/logos/discord.png" alt="Discord" boxSize="2em" title="Discord channel in building" />
          <Link href="https://opensea.io/collection/namomigos" isExternal mx={2}>
            <Image src="/assets/logos/opensea.png" alt="Opensea" boxSize="2em" />
          </Link>
        </Flex>
        <Spacer />

        {/* 右边的导航链接和按钮 */}
        <Flex justify="space-between" align="center" p={4}>
          {/* 在移动端隐藏导航链接 */}
          <Flex align="center" display={{ base: "none", md: "flex" }}>
            <Link href="#story" mx={2}>Story</Link>
            <Link href="#roadmap" mx={2}>Roadmap</Link>
            <Link href="#team" mx={2}>Team</Link>
            <Link href="#faq" mx={2}>FAQ</Link>
          </Flex>
          <Spacer />

          {/* Connect Wallet 按钮始终显示 */}
          <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 12,
          }}
        >
          <ConnectButton />
        </div>
        </Flex>
      </Flex>


      {/* 主要内容部分 */}
      <VStack my={8} align="center" spacing={8}>
        {/* 用Image组件代替Text组件来显示logo */}
        <Image src="/assets/logos/namomigos_logo.png" alt="Namomigos Logo" boxSize="500px" />
        {/* 确保src属性指向正确的图片路径 */}
       
        <Flex direction={{ base: "column", md: "row" }} w="full" align="start" justify="space-between">
          {/* 故事部分 */}
          <Box w={{ base: "full", md: "40%" }} pl={{ md: 12 }} p={4} textAlign="left" id="story">
            <Text fontSize="4xl" mb={8}>Story</Text>
            <Text>In the year 20XX, Earth has been entirely dominated by AI. Only a small fraction of human beings, animals, plants, obsolete robots, long-hidden aliens, and legendary creatures managed to survive, and they have all been reluctantly exiled to an 'AI made planet' known as 'The Temple', awaiting their fate. What awaits them there...?</Text>
          </Box>
          
          {/* NFT展示和Mint按钮 */}
          <VStack w={{ base: "full", md: "40%" }} p={4} align="center">
            {selectedNFT && <Image src={selectedNFT} alt="Featured NFT" boxSize="240px"/>}
            <Box w="full" display="flex" alignItems="center" justifyContent="center">
              <Input
                size="sm"
                width="100px"
                type="number"
                value={mintAmount}
                onChange={(e) => setMintAmount(Math.min(Math.max(1, e.target.value), 199))}
                placeholder="Enter amount"
              />
              <Text ml={2}>{`Mint Amount: ${mintAmount}`}</Text>
            </Box>
            <Button colorScheme="green" onClick={mintNFT}>
              Mint NFT
            </Button>
          </VStack>
        </Flex>
        <Text fontSize="md">{`Remaining NFTs: ${remainingNFTs}`}</Text>
      </VStack>

        {/* 弹出窗口 */}
          <AlertDialog
            isOpen={isAlertOpen}
            onClose={() => setIsAlertOpen(false)}
            isCentered
            >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Error
                  </AlertDialogHeader>
                    <AlertDialogBody>
                      {alertMessage}
                    </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button colorScheme="red" onClick={() => setIsAlertOpen(false)}>
                      Close
                    </Button>
                  </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
      {/* 铸造成功后的弹出窗口 */}
          <AlertDialog isOpen={isMintSuccessOpen} onClose={() => setIsMintSuccessOpen(false)} isCentered>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Mint Successful
                </AlertDialogHeader>
                <AlertDialogBody>
                  <Text>Your NFT has been minted successfully!</Text>
                  {/* 根据需要调整下面的链接 */}
                  <Link href={`https://polygonscan.com/address/0x256fa702a12a0a77750058b528c94819588fb3a5`} isExternal color="purple.500">
                    View on PolygonScan
                  </Link>
                  <br/>
                  <Link href={`https://opensea.io/collection/namomigos`} isExternal color="blue.500">
                    View on OpenSea
                  </Link>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button colorScheme="blue" onClick={() => setIsMintSuccessOpen(false)}>
                    Close
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

      {/* 已铸造的NFT展示部分 */}
      <VStack my={4} align="center">
        <Text fontSize="4xl">Already Minted</Text>
        <Box overflowX="auto" w="full" p={4}>
          <Flex className='auto-scroll'>
            {nftImages.map((img, index) => (
            <Image key={index} src={img} alt={`Minted NFT ${index}`} mx={2} boxSize="240px" />
            ))}
          </Flex>
        </Box>
      </VStack>
              
      {/* Roadmap部分 */}
      <VStack my={4} align="center" id="roadmap">
      <Text fontSize="4xl" mb={4}>Roadmap</Text>
      <Flex align="center" justify="center" p={4}>
        <RoadmapItem 
          items={[
            { img: '/assets/roadmap/nft is pain.png', text: 'NFT is pain' },
            { img: '/assets/roadmap/nft is pain 2.png', text: 'NFT is the origin of pain' },
            { img: '/assets/roadmap/nft is pain 3.png', text: 'NFT is the cessation of pain' },
            { img: '/assets/roadmap/nft is pain 4.png', text: 'NFT is the path leading to the cessation of pain' },
          ]} 
        />
      </Flex>
      </VStack>

      {/* 团队成员部分 */}
      <VStack my={4} align="center" id="team">
        <Text fontSize="4xl" mb={4}>Team Members</Text>
        <Flex direction={{ base: "column", md: "row" }} justify="center" w="full" wrap="wrap">
          {/* 左边的团队成员 */}
          <Flex direction="column" mx={{ base: "0", md: "10" }} mb={{ base: "10", md: "0" }}>
            <Flex justify="space-around" align="center">
              <TeamMember name="General Manager" bio="A customized GPT. Overseeing the overall operations of Namomigos." avatar="/assets/avatars/manager.png" />
              <TeamMember name="SNS Operator" bio="A customized GPT. In charge of writing tweets to promote." avatar="/assets/avatars/communicator.png" />
            </Flex>
            <Flex justify="space-around" align="center">
              <TeamMember name="Pixel Artist" bio="A customized GPT with DALL·E. Focus on drawing pixel arts." avatar="/assets/avatars/printer.png" />
              <TeamMember name="Python Engineer" bio="A customized GPT. Codes the Python script to generate layers and metadata files." avatar="/assets/avatars/python.png" />
            </Flex>
          </Flex>

          {/* 右边的团队成员 */}
          <Flex direction="column" mx={{ base: "0", md: "10" }}>
            <Flex justify="space-around" align="center">
              <TeamMember name="Front-End Engineer" bio="A customized GPT. Skilled in JS/TS. This website was built by her." avatar="/assets/avatars/jsts.png" />
              <TeamMember name="Solidity Engineer" bio="A customized GPT. Codes and audits the smart contracts." avatar="/assets/avatars/solidity.png" />
            </Flex>
            <Flex justify="space-around" align="center">
              <TeamMember name="GPT-3.5" bio="Takes over to chat with the person on the right when GPT-4 reaches its usage limit." avatar="/assets/avatars/7.png" />
              <TeamMember name="Nobody" bio="The only human-being in this team. Almost do nothing except typing s**t." avatar="/assets/avatars/8.png" />
            </Flex>
          </Flex>
        </Flex>
      </VStack>

      {/* FAQ部分 */}
      <VStack my={4} align="center" id="faq">
        <Text fontSize="4xl" mb={4}>FAQ</Text>
        <FAQItem 
          question="What is Namomigos?" 
          answers={[
            { text: "Namomigos is a 24 x 24 pixel style NFT Collection, with a total of 9,981 unique pieces, 153 traits(including 25 1 of 1s). It is inspired by Nakamigos and is a secondary creation based on Buddhist culture.", avatar: "/assets/avatars/communicator.png" },
            { text: "Nothing Funny Token.", avatar: "/assets/avatars/8.png" }
          ]} 
        />
        <FAQItem 
          question="When will the public sale start?" 
          answers={[
            { text: "1st Airdrop: 3 NFTs for each of 99 addresses. 2nd Airdrop: 2 NFTs for each of 99 addresses. Last Airdrop: 1 NFT for each of 99 addresses. No whitelist or pre-sale. Public sale starts after airdrops, price: 1.99 MATIC per NFT. Follow @Namomigos on Twitter for updates. Note: Reveal will be in the first week after the public sale starts, remember to refresh.", avatar: "/assets/avatars/communicator.png" },
            { text: "It depends on my mood.", avatar: "/assets/avatars/8.png" }
          ]} 
        />
        <FAQItem 
          question="Why is it deployed on Polygon?" 
          answers={[
            { text: "Polygon stands out with its efficient mechanisms, robust security, and speedy yet cost-effective transactions, making it a top choice for developing and deploying decentralized apps. Supporting a diverse multi-chain ecosystem, it seamlessly integrates with Ethereum, enhancing connectivity for tools, applications, and communities.", avatar: "/assets/avatars/manager.png" },
            { text: "Because the gas fees of ethereum mainnet is way too high.", avatar: "/assets/avatars/8.png" }
          ]} 
        />
        <FAQItem 
          question="What is your future plan?" 
          answers={[
            { text: "We will continue to explore the Polygon zkEVM and plan to deploy derivative products of Namomigos on Polygon zkEVM. Additionally, we are exploring the potential of AI-driven DAO management. Stay tuned for our upcoming developments.", avatar: "/assets/avatars/manager.png" },
            { text: "I have no idea what to do next.", avatar: "/assets/avatars/8.png" }
          ]} 
        />
      </VStack>

      {/* 页脚部分 */}
      <Box as="footer" p={4} textAlign="center">
        <Text>© Created by Namo Labs. All rights reserved.</Text>
      </Box>
    </ChakraProvider>
    </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
